<template>
  <b-overlay :show="loading">
    <section>
      <div>
        <div class="mb-2">
          <h2>Personal Information</h2>
        </div>
        <div>
          <b-card>
            <div class="card-body">
              <validation-observer ref="newUserForm" #default="{invalid}">
                <b-form ref="form" @submit.prevent="onUpdateUser">
                  <div class="mb-2">
                    <b-media class="text-center">
                      <b-avatar ref="previewEl" badge-variant="transparent" :src="avatarPath" :text="avatarText(`${currentUser.first_name} ${currentUser.last_name}`)" :variant="`light-${resolveStatusVariant(getLoanStatusTextForClient(getValueFromSource(currentUser, 'status')))}`" size="10rem">
                        <template #badge>
                          <b-button variant="primary" class="btn-icon rounded-circle" @click="$refs.avatar.$el.childNodes[0].click()">
                            <feather-icon icon="CameraIcon" />
                          </b-button>
                        </template>
                      </b-avatar>
                    </b-media>

                    <div>
                      <b-form-file v-show="false" ref="avatar" accept="image/*" placeholder="" no-drop @input="onImageRenderer" />
                    </div>
                  </div>

                  <!--first name -->
                  <div class="mb-1">
                    <b-form-group label="First Name" label-for="firstname">
                      <validation-provider #default="{ errors }" name="First Name" vid="firstname" rules="required">
                        <div class="exxtra-input">
                        <span class="leading">
                          <Person />
                        </span>
                          <input id="firstname" v-model="currentUser.first_name" autocomplete="false">
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!--last name -->
                  <div class="mb-1">
                    <b-form-group label="Last Name" label-for="lastname">
                      <validation-provider #default="{ errors }" name="Last Name" vid="lastname" rules="required">
                        <div class="exxtra-input">
                        <span class="leading">
                          <Person />
                        </span>
                          <input id="lastname" v-model="currentUser.last_name" autocomplete="false">
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!--Email -->
                  <div class="mb-1">
                    <b-form-group label="Email (Optional)" label-for="email">
                      <validation-provider #default="{ errors }" name="Email" rules="email" vid="email">
                        <div class="exxtra-input">
                        <span class="leading">
                          <RegistrationMail />
                        </span>
                          <input id="email" v-model="currentUser.email" autocomplete="false">
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!--Phone -->
                  <div class="mb-1">
                    <b-form-group label="Phone" label-for="phone">
                      <validation-provider #default="{ errors }" name="Phone" rules="required" vid="phone">
                        <div class="exxtra-input">
                        <span class="leading">
                          <RegistrationPhone />
                        </span>
                          <input id="phone" v-model="currentUser.phone" autocomplete="false" readonly>
                          <!-- <span v-if="getValueFromSource(currentUser, 'meta.phone_verified', false)" class="trailing">
                            <small class="text-success">verified</small>
                            <feather-icon class="text-success" icon="CheckCircleIcon" />
                          </span>
                          <span v-else class="trailing">
                            <small class="text-danger">pending </small>
                            <feather-icon class="text-danger" icon="AlertCircleIcon" />
                          </span> -->
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="mb-1">
                    <b-form-group label="Gender" label-for="gender">
                      <validation-provider #default="{ errors }" name="Gender" rules="required" vid="gender">
                        <b-form-select 
                          id="gender" 
                          v-model="currentUser.gender" 
                          :options="genderOptions"
                        />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div>
                    <b-button class="py-1" type="submit" variant="primary" block :disabled="invalid">
                      Update Profile
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </b-card>
        </div>
      </div>
    </section>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BForm,
  BMedia,
  BAvatar,
  BButton,
  BOverlay,
  BFormFile,
  BDropdown,
  BFormGroup,
  BCardTitle,
  BDropdownItem,
  BFormSelect,
} from "bootstrap-vue";
import { cloneDeep, get } from "lodash";
import { MUTATE_USER_DATA } from "@/store/config/mutation-keys";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Cleave from 'vue-cleave-component';
import Person from '@/assets/svg/Person.svg';
import ResidentialIcon from '@/assets/svg/ResidentialIcon.svg';
import RegistrationMail from '@/assets/svg/RegistrationMail.svg';
import RegistrationPhone from '@/assets/svg/RegistrationPhone.svg';

export default {
  name: "Update",
  components: {
    BCard,
    BForm,
    BMedia,
    Cleave,
    Person,
    BAvatar,
    BButton,
    BOverlay,
    BDropdown,
    BFormFile,
    BCardTitle,
    BFormGroup,
    BFormSelect,
    BDropdownItem,
    ResidentialIcon,
    RegistrationMail,
    RegistrationPhone,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      avatarPath: '',
      genderOptions: [
        {
          value: "female",
          text: "Female"
        },
        {
          value: "male",
          text: "Male"
        }
      ],
    }
  },
  watch: {
    currentUser: {
      handler() {
        this.avatarPath = this.getValueFromSource(this.currentUser, 'avatar.path', '');
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.userData = cloneDeep(this.currentUser)
    this.mfa_conf = this.userData.mfa_conf;
  },
  methods: {
    async onUpdateUser() {
      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("first_name", this.currentUser.first_name)
        formData.append("last_name", this.currentUser.last_name)
        formData.append("email", this.currentUser.email)
        formData.append("gender", this.currentUser.gender)

        if (this.updatedImage) {
          formData.append("avatar", this.updatedImage);
        }

        const response = await this.useJwt().authService.updateProfile(formData);
        const updated_user = get(response, 'data.data');
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, updated_user);

        if (this.updatedImage) {
          this.updatedImage = null;
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageRenderer(file) {
      const reader = new FileReader()

      reader.addEventListener(
          'load',
          () => {
            this.avatarPath = reader.result
            this.updatedImage = file;
          },
          false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  }
}
</script>

<style scoped>

</style>
