<template>
  <b-overlay :show="loading">
    <section>
      <div>
        <div class="mb-1">
          <b-card-title>
            <h2>Security Information</h2>
          </b-card-title>
        </div>
        <b-card>
          <div>
            <h4>Multi factor Authentication</h4>
          </div>
          <div>
            <p>Two-Factor authentication (2FA) provides an
              additional layer of security beyond passwords
              and it’s strongly recommended
            </p>
          </div>
          <div v-if="!mfa_conf" class="pt-1">
            <b-link :to="{ name: 'security-mfa'}">
              <b-button variant="primary">
                Setup Multi-factor Authentication
              </b-button>
            </b-link>
          </div>
          <div v-if="mfa_conf" class="pt-1">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <method :mfa-method="getValueFromSource(mfa_conf, 'mfa_method')" />
              </div>
              <div>
                <b-button
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="requestMfaDeactivation"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>

            </div>
          </div>
        </b-card>
      </div>
    </section>

    <!--modal -->
    <section>
      <div>
        <b-modal ref="otp-verification" hide-header-close size="sm" centered hide-footer>
          <div class="d-block text-center card-body">
            <div class="">
              <div>
                <h3>Almost there</h3>
              </div>
              <div>
                <p>Kindly provide the 6 digits from {{ getValueFromSource(mfa_conf, 'mfa_method') }}</p>
              </div>
              <div>
                <validation-observer ref="authenticateForm" #default="{invalid}">
                  <b-form @submit.prevent="deactivateMfa">
                    <!--                username -->
                    <b-form-group label-for="code">
                      <validation-provider
                          #default="{ errors }"
                          name="Code"
                          vid="code"
                          rules="required"
                      >
                        <div class="exxtra-input">
                          <span class="leading">
                        <PasswordLock />
                      </span>
                          <input id="login-username" v-model="code" type="number" class="otp"
                                 placeholder="* * * * *"
                          >
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <div>
                      <b-button
                          class="py-1"
                          type="submit"
                          variant="primary"
                          block
                          :disabled="invalid"
                      >
                        Disable MFA
                      </b-button>
                    </div>
                  </b-form>
                </validation-observer>

              </div>
            </div>
          </div>
        </b-modal>
      </div>

    </section>
  </b-overlay>
</template>

<script>
import { cloneDeep, get } from "lodash";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BOverlay, BCard, BButton, BLink, BCardTitle, BModal, BFormGroup, BForm } from "bootstrap-vue";

import Method from "@/pages/admin/auth/profile/method.vue";
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "MFA",
  components: {
    PasswordLock,
    Method,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCard,
    BFormGroup,
    BForm,
    BLink,
    BModal,
    BCardTitle,
  },
  data() {
    return {
      loading: false,
      mfa_conf: null,
      code: '',
      active_status: false,

    }
  },
  created(){
    this.userData = cloneDeep(this.currentUser)
    this.mfa_conf = this.userData.mfa_conf;
  },
  methods: {
    async requestMfaDeactivation() {
      // this.loading = true;
      try {
        const response = await this.useJwt()
            .mfaService
            .deactivateMfaRequest();
        const {
          mfa_deactivation_token
        } = response.data.data;
        this.deactivationToken = mfa_deactivation_token;
        await this.useJwt().mfaService.setMfaDeactivationToken(this.deactivationToken);
        this.$refs['otp-verification'].toggle('#toggle-btn')
      } catch (error) {
        console.log(error)
      } finally {
        // this.loading = true;
      }
    },
    async deactivateMfa() {
      this.loading = true;
      try {
        const payload = {
          token: this.code
        }
        const response = await this.useJwt()
            .mfaService
            .deactivateMfa(payload);

        await this.useJwt().mfaService.clearMfaDeactivationToken(this.deactivationToken);

        const { message } = response.data.data;
        this.$refs['otp-verification'].toggle('#toggle-btn')


        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mfa Successfully Deactivated',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: message
          },
        });

        this.mfa_conf = null;
        this.active_status = false;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        })
      } finally {
        this.loading = false
      }
    }

  }

}
</script>

<style scoped>

</style>
